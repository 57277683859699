import React from 'react';

// Define the type for the cookie data and the context interface
interface CookieData {
  [key: string]: string | null;
}

interface CookieContextType {
  cookieData: CookieData;
  logout: () => void;
  login: () => void;
  isLoggedIn: () => boolean;
  startTour: () => void;
  stopTour: () => void;
}

// Create the context with a default value
const CookieContext = React.createContext<CookieContextType | undefined>(undefined);

export default CookieContext;
