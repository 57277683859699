import React from 'react';

import './App.css';

import CookieContext from './CookieContext';

import Joyride, { ACTIONS, CallBackProps, EVENTS, Step } from 'react-joyride';

// Define the type for your state
interface AppState {
  cookieData: { [key: string]: string | null },
  isDarkMode: boolean,
  runTour: boolean,
  firstTime: boolean,
  stepIndex: number
}

interface AppProps {
  children?: React.ReactNode;
};

// Utility functions
function getCookie(name: string): string | null {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return decodeURIComponent(match[2]);
  return null;
}

function setCookie(name: string, value: string, days: number): void {
  document.cookie = `${name}=${value}; max-age=${days * 24 * 60 * 60}; path=/;`;
}

function removeCookie(name: string): void {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

class App extends React.Component<AppProps, AppState> {

  constructor(props: AppProps) {
    super(props);
    // Initialize state with cookie data

    this.updateStateFromCookie();
  }

  updateStateFromCookie = () => {
    this.state = {
      cookieData: {
        user: getCookie('name'),
        picture: getCookie('picture'),
        apiEndpoint: getCookie('api_endpoint'),
        email: getCookie('email'),
        playgroundApiKey: getCookie('playground_api_key'),
        givenName: getCookie('givenname'),
        // Add more cookies as needed
      },
      isDarkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
      runTour: false,
      firstTime: getCookie('firstTime') == null ? true : false,
      stepIndex: 0
    };
  }

  handleSystemThemeChange = (e: MediaQueryListEvent) => {
    this.setState({ isDarkMode: e.matches });
  };

  // Logout function to clear cookies and update state
  logout = () => {
    removeCookie('api_endpoint');
    removeCookie('email');
    removeCookie('givenname');
    removeCookie('name');
    removeCookie('nickname');
    removeCookie('picture');
    removeCookie('playground_api_key');
    removeCookie('session');
    removeCookie('surname');
    removeCookie('firstTime');

    // Update state to reflect logged-out status
    this.setState({
      cookieData: {
        user: null,
        picture: null,
        email: null,
        playgroundApiKey: null,
        givenName: null,
        // Reset other cookies as needed
      },
    });

    const local_dev = process.env.LOCAL_DEV === 'true';
    if (!local_dev) {
      // If we're not doing local dev, we need to redirect to the logout page so that Cloud-CDN-Cookie can be deleted
      const logoutUrl = `${window.location.protocol}//${window.location.host}/secured/logout.html`;
      // Redirect to the secured content so logout can happen
      window.location.href = logoutUrl;
    }
  };

  login = () => {

    if (process.env.LOCAL_DEV === 'true') {
      setCookie('name', 'John Doe', 1);
      setCookie('email', "john.doe@text2motion.ai", 1);
      setCookie('givenname', "John", 1);
      if (process.env.LOCAL_DEV_PLAYGROUND_API_KEY)
        setCookie('playground_api_key', process.env.LOCAL_DEV_PLAYGROUND_API_KEY, 1);
      if (process.env.API_ENDPOINT)
        setCookie("api_endpoint", process.env.API_ENDPOINT, 1);
      else
        setCookie("api_endpoint", "https://gamma.api.text2motion.ai", 1);

      this.updateStateFromCookie();
      this.setState(this.state);

      window.location.reload(); // Needed to ensure the state is updated
    }
    else {
      // Redirect.html already knows to redirect back to the root of the current host address, so we don't pass anything
      // This is a fix for an issue with __Secure-GCP_IAP_QUERY_PARAMS being set, which prevents redirect from working.
      const redirectUrl = `${window.location.protocol}//${window.location.host}/secured/redirect.html`;
      // Redirect to the secured content so login can happen
      window.location.href = redirectUrl;
    }
  }

  isLoggedIn = () => {
    this.updateStateFromCookie();
    return (this.context != null && this.state != null &&
      (process.env.LOCAL_DEV == 'true' ? // If we're doing local dev
        (getCookie("name") != null) : // We're logged in if we have a name
        (getCookie("playground_api_key") != null))); // Otherwise we need a playground API key
  }

  startTour = () => {
    if (this.state.firstTime) {
      this.setState({ runTour: true });
    }
  }

  stopTour = () => {
    this.setState({ runTour: false });
  }

  componentDidMount() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', this.handleSystemThemeChange);

    document.documentElement.setAttribute('data-theme', this.state.isDarkMode ? 'dark' : 'light');
  }

  componentWillUnmount() {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.removeEventListener('change', this.handleSystemThemeChange);
  }

  componentDidUpdate() {
    document.documentElement.setAttribute('data-theme', this.state.isDarkMode ? 'dark' : 'light');
  }

  tourStepCallback = (data: CallBackProps) => {
    var { index, step, type, action } = data;

    switch (index)
    {
      case 2:
        switch (type) {
          case EVENTS.STEP_AFTER:
            // Check if the target element has any text, don't proceed if not
            const targetElement = document.querySelector(step.target as string) as HTMLInputElement;
            switch (action) {
              case ACTIONS.NEXT:
                index = targetElement && (targetElement.value == '') ? index : index + 1;
                this.setState({ stepIndex: index });
                break;
              case ACTIONS.PREV:
                index = index - 1;
                this.setState({ stepIndex: index });
                break;
            }
            break;
        }
        break;
      default:
        switch (type) {
          case EVENTS.STEP_AFTER:
            switch (action) {
              case ACTIONS.CLOSE:
                if (index === 0)
                  this.setState({ runTour: true, stepIndex: 1 });
                break;
              case ACTIONS.NEXT:
                index = index + 1;
                this.setState({ stepIndex: index });
                break;
              case ACTIONS.PREV:
                index = index - 1;
                this.setState({ stepIndex: index });
                break;
            }
            break;
        }
        break;
    }

    // Make sure to flag the tour as done if it's the last step
    if (type === EVENTS.TOUR_END) {
      setCookie('firstTime', 'false', 365);
      this.setState({ firstTime: false });
    }
  }

  render() {
    const steps: Step[] = [
      {
        target: '#MyThree > canvas',
        placement: 'center',
        content: (
          <div style={{ textAlign: 'left' }}>
            Welcome to the Text2Motion Playground! <br />
            <br />
            You can use the mouse or your fingers to rotate, pan, and zoom the camera. <br />
            Let's get started animating!
          </div>
        ),
        showSkipButton: true,
        disableScrolling: true,
        disableBeacon: false,
        offset: 0,
        disableOverlay: true
      },
      {
        target: '#tweakpane > div.tp-rotv_c > div > div.tp-tabv_c > div.tp-tbpv.tp-v-fst.tp-v-vfst.tp-v-lst.tp-v-vlst > div > div.tp-lblv.tp-v-fst.tp-v-vfst > div.tp-lblv_v > div > select',
        content: (
          <div style={{ textAlign: 'left' }}>
            Use this dropdown to select a stock model, or use a custom one. It must:
            <ul>
              <li>Be a Biped</li>
              <li>Be a Mixamo rig</li>
              <li>Be in GLTF or GLB format</li>
            </ul>
          </div>
        ),
        placement: 'auto',
        disableScrolling: true,
        disableBeacon: false,
        showSkipButton: false,
        offset: 0,
        disableOverlay: true,
      },
      {
        target: '#tweakpane > div.tp-rotv_c > div > div.tp-tabv_c > div.tp-tbpv.tp-v-fst.tp-v-vfst.tp-v-lst.tp-v-vlst > div > div:nth-child(2) > div.tp-lblv_v > div > input',
        content: (
          <div style={{ textAlign: 'left' }}>
            Use this textbox to enter a descriptive prompt for the animation and press enter. You can try something like:<br />
            <i>A person walks while balancing with their arms</i><br />
            or<br />
            <i>Jump forward and then do a drop kick</i><br />
            <br />
            You can also add adjectives, adverbs and chain together events to indicate temporal relationships. A good rule of thumb is to describe the motions, instead of the character intent. Finding good prompts will help you create better animations.
          </div>
        ),
        placement: 'bottom',
        disableScrolling: true,
        showSkipButton: false,
        offset: 0,
        disableOverlay: true,
      },
      {
        target: 'body',
        content: (
          <div style={{ textAlign: 'left' }}>
            Congratulations, you just created a 3D animation!<br />
            <br />
          </div>
        ),
        placement: 'center',
        showSkipButton: false,
        offset: 0,
        disableOverlay: true,
      },
      {
        target: "#tweakpane > div.tp-rotv_c > div > div.tp-tabv_c > div.tp-tbpv.tp-v-fst.tp-v-vfst.tp-v-lst.tp-v-vlst > div > div.tp-lblv.tp-lblv-nol.tp-v-lst.tp-v-vlst > div.tp-lblv_v > div > div:nth-child(4) > button",
        content: (
          <div style={{ textAlign: 'left' }}>
            If you didn't like the animation, you can press this button to re-generate it. It's <i>really</i> fast, and our AI gives you a unique, original variation each time!<br />
          </div>
        ),
        placement: 'bottom',
        showSkipButton: false,
        offset: 0,
        disableOverlay: true,
      },
      {
        target: '#tweakpane > div.tp-rotv_c > div > div.tp-tabv_c > div.tp-tbpv.tp-v-fst.tp-v-vfst.tp-v-lst.tp-v-vlst > div > div.tp-lblv.tp-lblv-nol.tp-v-lst.tp-v-vlst > div.tp-lblv_v > div > div:nth-child(6) > button',
        content: (
          <div style={{ textAlign: 'left' }}>
            Use this button to download the animated 3D model in GLTF format. You can then use it in your projects, or share it with others.<br />
          </div>
        ),
        placement: 'auto',
        showSkipButton: false,
        offset: 0,
        disableOverlay: true,
      },
      {
        target: 'body',
        placement: 'center',
        content: (
          <div style={{ textAlign: 'left' }}>
            That's it! You've got the hang of it now.<br />
            <br />
            Try out different prompts, models or any of our integrations into your favorite tools<br />
            <b>Happy animating!</b>
          </div>
        ),
        showSkipButton: false,
        offset: 0,
        disableOverlay: true,
      },
    ];

    const { children } = this.props;
    const { cookieData, runTour, stepIndex } = this.state;

    return (
      <CookieContext.Provider value={{
        cookieData,
        login: this.login,
        logout: this.logout,
        isLoggedIn: this.isLoggedIn,
        startTour: this.startTour,
        stopTour: this.stopTour
      }}>
        <Joyride
          run={runTour}
          callback={this.tourStepCallback}
          steps={steps}
          stepIndex={stepIndex}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          locale={{
            open: 'Take the tour',
            last: 'Finish' // Change "Last" button to "Finish"
          }} />
        <div>
          {children}
        </div>
      </CookieContext.Provider>
    );
  }
}

export default App;